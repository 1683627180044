<template>
  <div id="mapContainer" class="baiduMap"></div>
</template>
<script>
import { defineComponent } from "vue";
// import { message } from "ant-design-vue";
export default defineComponent({
  components: {},
  data() {
    return {
      flag: 0,
    };
  },
  props: {
    isEdit: {
      type: Boolean,
      defaul: false,
    },
    baidu: {
      type: String,
      defaul: "116.403967,39.915109",
    },
  },
  methods: {
    bdMap(city = "天安门") {
      //创建地图
      let map = new BMap.Map("mapContainer");
      map.clearOverlays();
      let that = this;
      function sendposition(lngvalue, latvalue) {
        lngvalue = lngvalue.toFixed(6);
        latvalue = latvalue.toFixed(6);
        sessionStorage.setItem("lng", lngvalue);
        sessionStorage.setItem("lat", latvalue);
        that.$emit("changeBaidu", lngvalue + "," + latvalue);
      }
      map.addControl(new BMap.ScaleControl());
      map.addControl(
        new BMap.NavigationControl({
          anchor: BMAP_ANCHOR_TOP_RIGHT,
          type: BMAP_NAVIGATION_CONTROL_SMALL,
        })
      );
      map.enableScrollWheelZoom(true); //允许鼠标滚动缩放
      let baiduLon = 116.403967;
      let baiduLat = 39.915109;
      if (this.baidu) {
        baiduLon = Number(this.baidu.split(",")[0]);
        baiduLat = Number(this.baidu.split(",")[1]);
      }

      var point = new BMap.Point(baiduLon, baiduLat);
      map.centerAndZoom(point, 17);
      var marker = new BMap.Marker(point, {
        enableDragging: this.isEdit ? true : false,
      });
      map.addOverlay(marker);
      if (this.isEdit) {
        map.addEventListener("click", function (e) {
          sendposition(e.point.lng, e.point.lat);
          map.clearOverlays();
          let point = new BMap.Point(e.point.lng, e.point.lat);
          let marker = new BMap.Marker(point, {
            enableDragging: true,
          });
          map.addOverlay(marker); //添加覆盖物
          marker.addEventListener("dragend", function (e) {
            sendposition(e.point.lng, e.point.lat);
          });
        });
        marker.addEventListener("dragend", function (e) {
          sendposition(e.point.lng, e.point.lat);
        });
      }
    },
  },
  mounted() {
    this.bdMap();
  },
  watch: {
    baidu() {
      this.bdMap();
    },
  },
});
</script>
<style lang="scss" scoped>
.baiduMap {
  // width: 98%;
  height: 604px;
  width: 100%;
  // position: absolute;
  // left: 0;
  // right: 0;
  // margin: 1% auto;
}
</style>
<style>
.bm-view {
  width: 100%;
  height: 300px;
}
</style>