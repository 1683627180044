
import { defineComponent, reactive, ref } from "vue";
import Basics from "@/components/masterDataDetails/Basics.vue";
import Qualification from "@/components/masterDataDetails/Qualification.vue";
import { Form, Button, Input, message } from "ant-design-vue";
import {
  //   addTerminal,
  editTerminal,
  getTerminalDetails,
} from "@/api/terminalList.js";
import { terminalDetailsRules } from "@/rules/rules.js";
import { useRouter } from "vue-router";

const { Item } = Form;

export default defineComponent({
  data() {
    return {
      showCustom: false, //自定义字段未加载结束之前不显示
      isEdit: false, //点击编辑之后可以编辑
    };
  },
  components: {
    Basics,
    Qualification,
    Form,
    Button,
    FormItem: Item,
    Input,
  },
  setup() {
    const router = useRouter();
    const formRef = ref();
    const formState: any = reactive({
      terminalName: "",
      terminalCode: "",
      status: 1,
      province: [],
      town: "",
      address: "",
      baiduLon: "",
      baiduLat: "",
      kindsItem1: "",
      kindsItem2: "",
      channel: "",
      childChannel: "",
      scaleType: "连锁",
      taskStatus: [],
      doorPhoto: "",
      licensePhoto: "",
      licenseCode: "",
      licenseName: "",
      licenseAddress: "",
      licenseDay: "",
      licenseType: "",
      licenseExpiry: "",
      licenseScope: "",
      terminalExtra: {},
    });
    const rules: any = terminalDetailsRules;
    const onSubmit = () => {
      //去掉前后空格
      Object.keys(formState).forEach((key) => {
        if (typeof formState[key] == "string") {
          formState[key] = formState[key].trim();
        }
      });
      formRef.value
        .validate()
        .then(() => {
          //
          let keepFormState = { ...formState };
          let id = router.currentRoute.value.params.id;
          let province;
          let city;
          let area;
          let provinceCode;
          let cityCode;
          let areaCode;
          if (formState.province[0].name) {
            province = formState.province[0].name;
            city = formState.province[1].name;
            area = formState.province[2].name;
          } else {
            province = formState.defaultProvince;
            city = formState.defaultCity;
            area = formState.defaultArea;
          }
          if (formState.province[0].code) {
            provinceCode = formState.province[0].code;
            cityCode = formState.province[1].code;
            areaCode = formState.province[2].code;
          } else {
            provinceCode = formState.provinceCode;
            cityCode = formState.cityCode;
            areaCode = formState.areaCode;
          }
          let params: any = JSON.parse(JSON.stringify(formState));
          params.provinceCode = provinceCode;
          params.cityCode = cityCode;
          params.areaCode = areaCode;
          params.province = province;
          params.city = city;
          params.area = area;
          params.baiduLon = sessionStorage.getItem("lng");
          params.baiduLat = sessionStorage.getItem("lat");
          delete params.terminalExtra;
          delete params.doorPhotoUpload;
          delete params.extraList;
          delete params.licensePhotoUpload;
          delete params.imgFlagDoor;
          delete params.imgFlagLicense;
          delete params.defaultProvince;
          delete params.defaultCity;
          delete params.defaultArea;
          //新增终端
          if (id === "new") {
            params.status = 1;
            // addTerminal({ ...params }).then((res) => {
            //   //
            //   if (res.data.success) {
            //     // message.success(`${res.data.data}`);
            //     router.push("/masterData/terminal/list");
            //   }
            //   // else {
            //   //   location.reload();
            //   // }
            // });
          }
          //编辑终端
          else {
            if (params.doorPhoto) {
              if (params.doorPhoto.includes("http")) {
                delete params.doorPhoto;
              }
            }
            if (params.licensePhoto) {
              if (params.licensePhoto.includes("http")) {
                delete params.licensePhoto;
              }
            }
            params.id = id;

            editTerminal({
              terminalCO: {
                fmesCode: params.id,
                storeShortId: params.storeShortId,
                fmesName: params.terminalName,
                province: params.province,
                city: params.city,
                area: params.area,
                town: params.town,
                address: params.address,
                kindCategory: params.terminalCategory[0],
                kind: params.terminalCategory[1],
                channel: params.terminalCategory[2],
                subChannel: params.childChannel,
                scaleType: params.scaleType,
                baiduLat: params.baidu.split(",")[1].trim(),
                baiduLon: params.baidu.split(",")[0].trim(),
              },
              terminalLicenseCO: {
                fmesCode: params.id,
                licensePhoto: params.licensePhoto || params.defaultlicensePhoto,
                socialCreditCode:
                  params.licenseCode == "" ? null : params.licenseCode,
                legalRepresentative:
                  params.licenseName == "" ? null : params.licenseName,
                licenseAddress:
                  params.licenseAddress == "" ? null : params.licenseAddress,
                licenseType:
                  params.licenseType == "" ? null : params.licenseType,
                licenseDay: params.licenseDay == "" ? null : params.licenseDay,
                licenseExpiry:
                  params.licenseExpiry == "" ? null : params.licenseExpiry,
                licenseScope:
                  params.licenseScope == "" ? null : params.licenseScope,
                // taxType: "",
              },
            })
              .then((res) => {
                //
                if (res.data.success) {
                  // message.success(`${res.data.data}`);
                  router.push("/terminalList");
                } else {
                  params = keepFormState;
                }
              })
              .catch(() => {
                params = keepFormState;
              });
          }
        })
        .catch(() => {
          message.warning("录入信息不规范请修改");
        });
    };
    const cancel = () => {
      history.go(-1);
    };
    return {
      formRef,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      other: "",
      formState,
      rules,
      onSubmit,
      cancel,
    };
  },
  methods: {
    //验证图片是否有效
    checkImg(src) {
      if (src) {
        if (src.includes(".jp") || src.includes(".png")) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
  mounted() {
    getTerminalDetails(this.$route.params.id).then((res: any) => {
      let result = res.data.data.terminalCO;
      this.formState.terminalName = result.fmesName;
      this.formState.terminalCode = result.fmesCode;
      this.formState.storeShortId = result.storeShortId;
      //   this.formState.status = result.status;
      this.formState.province = [result.province, result.city, result.area];
      this.formState.town = result.town;
      this.formState.address = result.address;
      this.formState.baiduLon = result.baiduLon ? result.baiduLon + "" : "";
      this.formState.baiduLat = result.baiduLat ? result.baiduLat + "" : "";
      this.formState.baidu = result.baiduLon + "," + result.baiduLat;
      sessionStorage.setItem(
        "lng",
        result.baiduLon ? result.baiduLon + "" : ""
      );
      sessionStorage.setItem(
        "lat",
        result.baiduLat ? result.baiduLat + "" : ""
      );
      this.formState.kindsItem1 = result.kindCategory;
      this.formState.kindsItem2 = result.kind;
      this.formState.channel = result.channel;
      this.formState.childChannel = result.subChannel;
      this.formState.scaleType = result.scaleType;
      this.formState.taskStatus = result.taskStatus
        ? result.taskStatus.split(";")
        : [];
      if (result.kindCategory && result.kind && result.channel) {
        this.formState.terminalCategory = [
          result.kindCategory,
          result.kind,
          result.channel,
        ];
      } else {
        this.formState.terminalCategory = [];
      }
      this.formState.defaultProvince = result.province;
      this.formState.defaultCity = result.city;
      this.formState.defaultArea = result.area;
      this.formState.terminalUnifyTypeCO =
        res.data.data.terminalCO.terminalUnifyTypeCO; //认证状态相关
      this.formState.areaCode = result.areaCode;
      let result2 = res.data.data.terminalLicenseCO;
      if (result2) {
        this.formState.licenseCode = result2.socialCreditCode;
        this.formState.licenseName = result2.legalRepresentative;
        this.formState.licenseAddress = result2.licenseAddress;
        this.formState.licenseDay = result2.licenseDay;
        this.formState.licenseType = result2.licenseType;
        this.formState.licenseExpiry = result2.licenseExpiry;
        this.formState.licenseScope = result2.licenseScope;
        this.formState.imgFlagLicense = this.checkImg(result2.licensePhotoAbs);
        this.formState.licensePhoto = result2.licensePhotoAbs;
        this.formState.defaultlicensePhoto = result2.licensePhoto;
        // this.formState.provinceCode = result.provinceCode;
        // this.formState.cityCode = result.cityCode;
      }
      //   this.formState.licensePhoto = result.licensePhoto;
      //   this.formState.doorPhoto = result.doorPhoto;
    });
  },
});
