
import { defineComponent } from "vue";
import {
  Form,
  Button,
  Input,
  Select,
  AutoComplete,
  Upload,
  Image,
  message,
} from "ant-design-vue";
import {
  PlusOutlined,
  LoadingOutlined,
  UnorderedListOutlined,
  CloseOutlined,
  PictureOutlined,
  EyeFilled,
  EditFilled,
} from "@ant-design/icons-vue";
import { licenseIdentification, imageUpload } from "@/api/terminalList.js";
const { Item } = Form;
const { Option } = Select;

export default defineComponent({
  components: {
    Form,
    Button,
    FormItem: Item,
    Input,
    Select,
    Option,
    AutoComplete,
    Upload,
    PlusOutlined,
    LoadingOutlined,
    UnorderedListOutlined,
    Image,
    message,
    CloseOutlined,
    PictureOutlined,
    EyeFilled,
    EditFilled,
  },
  props: {
    datalist: {
      type: Object,
      defaul: {},
    },
    isEdit: {
      type: Boolean,
      defaul: false,
    },
  },
  data() {
    return {
      imageUrl: "",
      notUploaded: true,
      iconShow: false,
      firstImg: "",
    };
  },
  methods: {
    //上传图片
    handleChange(info) {
      if (info.fileList.length < 1) {
        this.notUploaded = true;
        this.iconShow = false;
      }
    },
    //检验图片格式
    beforeUpload(file, fileList) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        message.error("上传图片应为PNG或JPG格式,请重新上传", 4);
        fileList.length = 0;
      }
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        message.error("图片大小应小于5M,请重新上传", 4);
        fileList.length = 0;
      }

      return isJpgOrPng && isLt5M;
    },
    getDate() {
      var date = new Date();
      var seperator1 = "";
      var year = date.getFullYear();
      var month: any = date.getMonth() + 1;
      var strDate: any = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      var currentdate = year + seperator1 + month + seperator1 + strDate;
      return currentdate;
    },
    customRequest(data) {
      const formData = new FormData();
      let name = data.file.name;
      name = name.substring(name.lastIndexOf(".") + 1);
      formData.append("file", data.file);
      formData.append("module", `terminal`);
      formData.append("scene", `license`);
      formData.append("format", name);
      imageUpload(formData)
        .then((res) => {
          this.notUploaded = false;
          (this as any).firstImg = res.data.data.absoluteFileUrl;
          (this as any).datalist.licensePhoto = res.data.data.fileUrl;
          data.onSuccess();
          this.iconShow = true;
          if (res.data.success) {
            // message.success(`${info.file.name} 图片上传成功`);
            //拿到数据
            let url = res.data.data.absoluteFileUrl;
            url = encodeURIComponent(url);

            licenseIdentification(url).then((res: any) => {
              let result = res.data.data;
              (this as any).datalist.licenseCode = result.licenseCode;
              setTimeout(() => {
                (this as any).datalist.licenseName = result.licenseName;
              }, 0);
              (this as any).datalist.licenseAddress = result.licenseAddress;
              setTimeout(() => {
                (this as any).datalist.licenseDay = result.licenseDay;
              }, 0);
              (this as any).datalist.licenseType = result.licenseType;
              setTimeout(() => {
                (this as any).datalist.licenseExpiry = result.licenseExpiry;
              }, 0);
              (this as any).datalist.licenseScope = result.licenseScope;
              this.iconShow = false;
            });
          } else {
            // message.error(`图片上传失败`);
            this.iconShow = false;
          }
        })
        .catch(() => {
          this.iconShow = false;
        });
    },
    changeImg() {
      (this as any).datalist.imgFlagLicense = false;
      this.notUploaded = true;
    },
    //预览图片
    previewImg() {
      let photo: any = document.querySelector(".previewPhoto2");
      setTimeout(() => {
        if (photo) {
          photo.click();
        }
      }, 0);
    },
  },
  mounted() {},
});
