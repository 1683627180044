
import { defineComponent } from "vue";
import {
  Form,
  Button,
  Input,
  Cascader,
  Select,
  AutoComplete,
  Upload,
  Image,
  message,
  Popover,
} from "ant-design-vue";
import {
  provinceCityAread,
  getTown,
  subchannelList,
} from "@/api/terminalList.js";
import {
  PictureOutlined,
  LoadingOutlined,
  CloseOutlined,
  EyeFilled,
  EditFilled,
  QuestionCircleOutlined,
} from "@ant-design/icons-vue";
import AllCategory from "@/components/modification/AllCategory.vue";
import BaiduMap from "./Baidu.vue";
const { Item } = Form;
const { Option } = Select;

export default defineComponent({
  components: {
    Form,
    Button,
    FormItem: Item,
    Input,
    BaiduMap,
    Cascader,
    Select,
    Option,
    AutoComplete,
    Upload,
    PictureOutlined,
    LoadingOutlined,
    Image,
    message,
    CloseOutlined,
    EyeFilled,
    EditFilled,
    QuestionCircleOutlined,
    Popover,
    TerminalCategoryCascader: AllCategory,
  },
  props: {
    datalist: {
      type: Object,
      defaul: {},
    },
    isEdit: {
      type: Boolean,
      defaul: false,
    },
  },
  data() {
    return {
      options: [],
      cityCurrent: 0,
      townOptions: [],
      imageUrl: "",
      notUploaded: true,
      cacheAreacode: {},
      firstImg: "",
      Illusion: true, //假象
      optionsSubChannel: [], //子渠道的选项
    };
  },
  methods: {
    //省市区失去焦点
    blurRegionValue() {
      if (this.cityCurrent !== 1) {
        this.cityCurrent = 0;
      }
      let cacheAreacode =
        (this as any).datalist.province[2].code ||
        (this as any).datalist.areaCode;
      getTown(cacheAreacode).then((res: any) => {
        //
        this.townOptions = res.data.data.map((item) => {
          return { value: item.name };
        });
      });
    },
    changeCascader() {
      (this as any).datalist.town = "";
    },
    //上传图片
    handleChange(info) {
      // if (info.file.status === "done") {
      //   message.success(`${info.file.name} 图片上传成功`);
      //   //拿到数据
      //   //
      // } else if (info.file.status === "error") {
      //   message.error(`${info.file.name} 图片上传失败`);
      // }
      if (info.fileList.length < 1) {
        this.notUploaded = true;
      }
    },
    //检验图片格式
    beforeUpload(file, fileList) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        message.error("上传图片应为PNG或JPG格式,请重新上传", 4);
        fileList.length = 0;
      }
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        message.error("图片大小应小于5M,请重新上传", 4);
        fileList.length = 0;
      }

      return isJpgOrPng && isLt5M;
    },
    getDate() {
      var date = new Date();
      var seperator1 = "";
      var year = date.getFullYear();
      var month: any = date.getMonth() + 1;
      var strDate: any = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      var currentdate = year + seperator1 + month + seperator1 + strDate;
      return currentdate;
    },
    customRequest(data) {
      const formData = new FormData();
      let name = data.file.name;
      name = name.substring(name.lastIndexOf(".") + 1);
      formData.append("file", data.file);
      formData.append("module", `terminal`);
      formData.append("scene", `terminaldoor`);
      formData.append("format", name);
      //   imageUpload(formData).then((res) => {
      //     (this as any).datalist.doorPhoto = res.data.data.fileUrl;
      //     (this as any).firstImg = res.data.data.absoluteFileUrl;
      //     this.notUploaded = false;
      //     setTimeout(() => {
      //       data.onSuccess();
      //     }, 0);
      //   });
    },
    changeImg() {
      (this as any).datalist.imgFlagDoor = false;
      this.notUploaded = true;
    },
    handleIllusion() {
      (this as any).datalist.province = [];
      this.Illusion = false;
      setTimeout(() => {
        let input: any = document.querySelector(".cascader");
        if (input) {
          input.click();
        }
      }, 0);
    },
    //预览图片
    previewImg() {
      let photo: any = document.querySelector(".previewPhoto");
      setTimeout(() => {
        if (photo) {
          photo.click();
        }
      }, 0);
    },
    //搜索子渠道
    searchSubChannel(val) {
      subchannelList(val).then((res: any) => {
        let array = res.data.data.map((item) => {
          return { value: item.subChannelName, label: item.subChannelName };
        });
        (this as any).optionsSubChannel = [...array];
      });
    },
    //子渠道值改变
    changeSubChannel(value) {
      if (value != "") {
        (this as any).datalist.scaleType = "连锁";
      }
      if (value === "" || value === null || value === undefined) {
        (this as any).datalist.scaleType = "独立";
      }
    },
    //获取省市区的联级
    getOptions() {
      function recursion(array) {
        array.forEach((element) => {
          element.value = element.name;
          element.label = element.name;
          if (element.children) {
            recursion(element.children);
          }
        });
      }
      provinceCityAread().then((res: any) => {
        recursion(res.data.data);
        this.options = res.data.data;
      });
    },
    changeBaidu(point) {
      (this as any).datalist.baidu = point;
    },
  },
  mounted() {
    this.getOptions();
    this.searchSubChannel("");
  },
});
