import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Basics = _resolveComponent("Basics")!
  const _component_Qualification = _resolveComponent("Qualification")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Form_item = _resolveComponent("Form-item")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createBlock(_component_Form, {
    model: _ctx.formState,
    rules: _ctx.rules,
    ref: "formRef",
    "label-col": { style: { width: '110px' } }
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Basics, {
        datalist: _ctx.formState,
        isEdit: _ctx.isEdit
      }, null, 8, ["datalist", "isEdit"]),
      _createVNode(_component_Qualification, {
        datalist: _ctx.formState,
        isEdit: _ctx.isEdit
      }, null, 8, ["datalist", "isEdit"]),
      _createVNode(_component_Form_item, {
        "wrapper-col": { span: 14, offset: 10 },
        style: {"margin-top":"20px"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Button, {
            style: {"margin-right":"20px","width":"100px","height":"40px"},
            onClick: _ctx.cancel
          }, {
            default: _withCtx(() => [
              _createTextVNode("取消 ")
            ]),
            _: 1
          }, 8, ["onClick"]),
          (_ctx.isEdit)
            ? (_openBlock(), _createBlock(_component_Button, {
                key: 0,
                style: {"width":"100px","height":"40px"},
                type: "primary",
                onClick: _ctx.onSubmit,
                loading: this.$store.state.loadingStatus
              }, {
                default: _withCtx(() => [
                  _createTextVNode("提交 ")
                ]),
                _: 1
              }, 8, ["onClick", "loading"]))
            : (_ctx.$store.state.authc.authc.ButtonBasic_pg_basiData_terminalDetails_edit)
              ? (_openBlock(), _createBlock(_component_Button, {
                  key: 1,
                  style: {"width":"100px","height":"40px"},
                  type: "primary",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isEdit = true))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("编辑 ")
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model", "rules"]))
}